import logo from './prabhuji.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <img src={logo} className="App-logo" alt="logo" />
        </div>

        <br></br> <br></br>
        <p>
          Welcome to Śri Siddha Gufa Yoga Shikshan Kendra's official website. (under construction)
        </p>
        <a
          className="App-link"
          href="https://goo.gl/maps/ZgenrkWRv7yv1mpa6"
          target="_blank"
          rel="noopener noreferrer"
        >
          Maps
        </a>
        <a
          className="App-link"
          href="https://mail.google.com/mail/u/0/?fs=1&to=siddhgufa@gmail.com&tf=cm"
          target="_blank"
          rel="noopener noreferrer"
        >
          Gmail
        </a>
        <a
          className="App-link"
          href="https://youtube.com/@SiddhaYogaAshram"
          target="_blank"
          rel="noopener noreferrer"
        >
          Youtube
        </a>
        <a
          className="App-link"
          href="https://facebook.com/siddhgufasawai"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <a
          className="App-link"
          href="https://instagram.com/siddha.gufa"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
        <a
          className="App-link"
          href="https://twitter.com/SiddhaGufa"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </a>
      </header>
    </div>
  );
}

export default App;
